// src/pages/ViewCase.js

import React, { useState } from 'react';
import { Container, Form, Button, ListGroup, Badge } from 'react-bootstrap';

function ViewCase() {
  const [inputCaseId, setInputCaseId] = useState('');
  const [caseId, setCaseId] = useState('');
  const [caseData, setCaseData] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [status, setStatus] = useState('');

  const fetchCase = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/get-case/${inputCaseId}`
    );
    if (response.ok) {
      const data = await response.json();
      setCaseData(data);
      setStatus(data.status);
      setCaseId(inputCaseId);
    } else {
      alert('Case not found.');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/add-comment/${caseId}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ comment: newComment }),
      }
    );
    setNewComment('');
    fetchCase();
  };

  return (
    <Container className="mt-5">
      {!caseData ? (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            fetchCase();
          }}
        >
          <Form.Group controlId="caseId">
            <Form.Label>Enter Your Case ID</Form.Label>
            <Form.Control
              type="text"
              value={inputCaseId}
              onChange={(e) => setInputCaseId(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            View Case
          </Button>
        </Form>
      ) : (
        <div>
          <h2>
            Viewing Case: <Badge bg="secondary">{caseId}</Badge>
          </h2>
          <p>
            <strong>Status:</strong> <Badge bg="info">{status}</Badge>
          </p>
          <h3>Case Details</h3>
          <p>{caseData.details}</p>
          <h3 className="mt-4">Conversation</h3>
          <ListGroup className="mb-4">
            {caseData.comments.length > 0 ? (
              caseData.comments.map((comment, index) => (
                <ListGroup.Item key={index}>
                  <strong>{comment.sender === 'admin' ? 'Admin' : 'You'}:</strong> {comment.comment}
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item>No comments yet.</ListGroup.Item>
            )}
          </ListGroup>
          <Form onSubmit={handleCommentSubmit}>
            <Form.Group controlId="newComment">
              <Form.Label>Add More Information</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Provide additional details..."
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Add Comment
            </Button>
          </Form>
        </div>
      )}
    </Container>
  );
}

export default ViewCase;
