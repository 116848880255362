// src/components/Footer.js
import React from 'react';
import { Container } from 'react-bootstrap';

function AppFooter() {
  return (
    <footer className="bg-dark text-light py-3 mt-5">
      <Container className="text-center">
        &copy; {new Date().getFullYear()} Landlord Web Solutions D/B/A Rentsync. All rights reserved.
      </Container>
    </footer>
  );
}

export default AppFooter;
