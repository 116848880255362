// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Alert, Button } from 'react-bootstrap';

function Home() {
  return (
    <Container className="mt-5">
      <Alert variant="warning">
        To remain anonymous, please use a VPN when accessing this site.
      </Alert>
      <h1 className="mb-4">Welcome to the anonymous Whistleblower Portal</h1>
      <div>
        <Button variant="primary" as={Link} to="/create-case" className="me-2">
          Create a New Case
        </Button>
        <Button variant="secondary" as={Link} to="/view-case">
          View Existing Case
        </Button>
      </div>
    </Container>
  );
}

export default Home;
