// src/pages/CreateCase.js

import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';

function CreateCase() {
  const [details, setDetails] = useState('');
  const [caseId, setCaseId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/create-case`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ details }),
      }
    );
    const data = await response.json();
    setCaseId(data.caseId);
  };

  const downloadAsTxt = () => {
    const fileContent = `Your Case ID: ${caseId}

Please keep this Case ID secure. You will need it to access your case and view updates. Do not share this ID with anyone else.

Thank you for your submission.`;

    const element = document.createElement('a');
    const file = new Blob([fileContent], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `case_${caseId}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const downloadAsCsv = () => {
    const csvContent = `Case ID\n${caseId}`;
    const element = document.createElement('a');
    const file = new Blob([csvContent], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `case_${caseId}.csv`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Container className="mt-5">
      {caseId ? (
        <div>
          <Alert variant="success">
            <h4>Your Case ID:</h4>
            <p>{caseId}</p>
            <p>Please save this ID to access your case in the future.</p>
          </Alert>
          <div className="mt-3 d-flex">
            <Button variant="primary" onClick={downloadAsTxt} className="me-2">
              Download as TXT
            </Button>
            <Button variant="primary" onClick={downloadAsCsv}>
              Download as CSV
            </Button>
          </div>
          <Alert variant="info" className="mt-3">
            <p>
              Please download and keep the Case ID in a safe
              place. You will need it to access your case in the future. Check back daily for updates to your case. 
            </p>
          </Alert>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="caseDetails">
            <Form.Label>Describe Your Case</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              placeholder="Provide detailed information about your case..."
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Submit Case
          </Button>
        </Form>
      )}
    </Container>
  );
}

export default CreateCase;
